import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap-sass');
require('gsap');

var $header = $('body:not(.home) header'),
  didScroll = false,
  headerHeight = $header.outerHeight(),
  lastScrollTop = 0,
  scrollDelta = 5;

// Dynamic header
function hasScrolled() {
  var scrollTop = $(window).scrollTop();
  if (Math.abs(lastScrollTop - scrollTop) <= scrollDelta)
    return;
  if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
    $header.addClass('header-scroll-down');
  }
  else if (scrollTop + $(window).height() < $(document).height()) {
    $header.removeClass('header-scroll-down');
  }
  lastScrollTop = scrollTop;
}

$(window).on('scroll', function () {
  didScroll = true;
});

setInterval(function () {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 250);

//Dynamic style on header
$('#navbar').on('show.bs.collapse hide.bs.collapse', function (event) {
  $(event.target).closest('.navbar').toggleClass('js-navbarOpen');
});


//Dynamic height for textarea
function elasticArea() {
  $('.js-elasticArea').each(function (index, element) {
    var $elasticElement = $(element),
      delta = parseInt($elasticElement.css('paddingBottom'), 10) + parseInt($elasticElement.css('paddingTop'), 10) || 0,
      elasticElement = element,
      initialHeight = initialHeight || $elasticElement.height();

    function resize() {
      $elasticElement.height(initialHeight);
      $elasticElement.height(elasticElement.scrollHeight - delta);
    }

    $elasticElement.on('input change keyup', resize);
    resize();
  });

}
elasticArea();

//Focus on form group
$('.form-control').on('focus blur', function (event) {
  $(event.target).closest('.form-group').toggleClass('focus');
});

// VOEUX
// ---------------

if ($('.view-voeux').length > 0) {

  function splitTextInWords(element) {

    var $element = $(element),
      paragraph = $element.html(),
      wordArray = paragraph.replace(/\n/g, ' \n<br> ').split(' ');

    // Split paragraph in words
    $.each(wordArray, function (i, val) {
      if (val.match(/meilleurs/) || val.match(/vœux/) || val.match(/2018/)) {
        wordArray[i] = '<span class="word js-nocasper">' + val + ' </span>';
      }
      else if (!val.match(/\n/) && val !== '') {
        wordArray[i] = '<span class="word js-casper">' + val + ' </span>';
      }
    });

    var newText = wordArray.join(' ');

    $element.html(newText);

  }

  // Init function
  splitTextInWords('.view-voeux .p');

  var $br = $('.view-voeux .p br'),
    $casper = $('.view-voeux .js-casper'),
    $nocasper = $('.view-voeux .js-nocasper');

  var timelineToReveal = new TimelineLite();
  var timelineToHide = new TimelineLite();

  var isComplete = true;

  function reveal() {
    timelineToReveal
      .to($casper, 1.5, { opacity: 0.4 })
      .to($nocasper, 2, { color: '#444' }, '-=1.5')
      .to($casper, 2, { fontSize: 0 })
      .to($nocasper, 1.5, { fontSize: '26px', textTransform: 'capitalize', fontWeight: 'bold' }, '-=0.5')
      .to($br, 0.5, { display: 'none' }, '-=2');
  }

  function hide() {
    timelineToHide
      .to($casper, 1.5, { opacity: 1, fontSize: '18px' })
      .to($br, 0.5, { display: 'block' })
      .to($nocasper, 2, { color: 'white', fontSize: '18px', textTransform: 'initial', fontWeight: 'normal' }, '-=1.5');
  }

  $('.js-boo').on('click', function () {
    if (isComplete) {
      reveal();
      isComplete = false;
    }
    else {
      hide();
      isComplete = true;
    }
  });

}
